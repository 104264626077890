import axios from "axios";
import firebase from "firebase";

export default class Recipe {
  remoteUrl = "https://us-central1-food-delights.cloudfunctions.net/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = firebase.auth().currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Share a recipe using cloud function
  async share(key, email) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "shareRecipe/" + key,
      data: { email: email },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Remove a recipe share using cloud function
  async remove(key, familyKey) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "delete",
      url: this.remoteUrl + "removeShare/" + key,
      data: { familyKey: familyKey },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Upload an image using cloud function
  async uploadImage(key, filename, image) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "put",
      url: this.remoteUrl + "uploadRecipeImage/" + key,
      data: { filename: filename, image: image },
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Delete an image using cloud function
  async deleteImage(key, imageKey) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "delete",
      url: this.remoteUrl + "deleteRecipeImage/" + key + "/" + imageKey,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }
}
